import React from 'react'
import './NoKidsMessage.css'

function NoKidsMessage(props) {
    return(
        <>
            <div className='container'>
                <h1>Let's get started!</h1>
                <h2>Set up a Learner's Journal and start documenting your home education journey</h2>
            </div>
        </>
    )
}

export default NoKidsMessage