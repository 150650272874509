import React, { useState } from 'react'; 
import { Avatar, Button, Card, CardHeader, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './AddKidCard.css';
import CardActionArea from '@material-ui/core/CardActionArea';
import { db, auth, analytics } from '../services/Firebase';
import Modal from '@material-ui/core/Modal';
import AddIcon from '../img/addicon.png';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));



function KidCard(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const [newChildName, setNewChildName] = useState('');

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const makeNewChild = (event) => {
      event.preventDefault();

      // add new child to kids collection
      db.collection('kids').add({
        name: newChildName
      })
      // then add reference to child to users collection (so they have a list of kids they can access)
      // duplicates kids name because this will save needing two accesses in kidlist
      .then(function(docref) {
        db.collection('users').doc(auth.currentUser.uid).collection('kids').doc(docref.id).set({
          name: newChildName
        });
        setNewChildName('');
        setOpen(false);
        
        analytics.logEvent('child_added');
      })
      .catch(function(error) {
        console.error("Error adding child: ", error);
      })

      
    }

    const body = (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Add your child details</h2>
          <form className="addKidCard__addKidForm">
            <Input 
              placeholder="Name"
              type="text"
              value={newChildName}
              onChange={(e) => setNewChildName(e.target.value)}  
            />
            <Button type="submit" onClick={makeNewChild}>Create</Button>
          </form>
      </div>
    );

    return (
      <>

        <Card className="AddKidCard__card">
            <CardActionArea className="AddKidCard__content" onClick={handleOpen}>
              <CardHeader
                title="Add new Journal" 
                titleTypographyProps={{variant:'subtitle1' }}
                avatar={<Avatar alt="Add" src={AddIcon}></Avatar>}
            />

            </CardActionArea>
        </Card>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
        </>
    )
}

export default KidCard