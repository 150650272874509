import React from 'react';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {auth} from './Firebase';
import firebase from "firebase/app";
import './UserAuth.css'

// setup FirebaseUI
const uiConfig = {
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: window.location.href + 'feed/',
    signInSuccessUrl:'/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ]
  };

  export default function Login(){
    const currentUser = auth.currentUser

    if(!currentUser){
        return( 
            <>
                <div className="login__firebase">
                    <FirebaseLoginUI></FirebaseLoginUI>
                </div>
            </>
        )
    } else {
        return <></>
    }
}

function FirebaseLoginUI(){
    const currentUser = auth.currentUser
    if(!currentUser){
        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
    }
} 