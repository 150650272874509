import React from 'react'; 
import { auth } from '../services/Firebase';
import './Navbar.css';
import BannerIcon from '../img/logo.svg';
import { Button} from '@material-ui/core';
import { useAuthState } from 'react-firebase-hooks/auth';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createMuiTheme } from '@material-ui/core/styles';

// todo: fix logout, does not refresh back to start + leaves logout button on screen until refresh
//        but at least it isn't erroring
function logoutActivities(props) {
  auth.signOut();
  props.setUser(null);
}

function NavBar(props) {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00D09E',
        mainGradient: "linear-gradient(#00D09E, #00ff93)",
      },
      secondary: {
        main: '#AEC2A7',
      },
    },
    typography: {
      fontFamily: [
        'Quicksand',
        'Arial',
        'sans-serif'
      ].join(','),
      fontWeight: 600
    }
  });

    const [user] = useAuthState(auth);

    return(
      <>
        <AppBar position="fixed" style={{ background: theme.palette.primary.mainGradient }}>
          <Toolbar>
          {props.user && props.kid && 
            <div className="app__header__back">
              <ArrowBackIosIcon onClick={() => props.setKid(null)}></ArrowBackIosIcon>
            </div>
          }
          
          <img
            className="app__headerImage"
            src={BannerIcon}
            alt="Logo"
          />

          <div className="app__header_authcontainer">
            { user ? (
              <Button onClick={() => logoutActivities(props)}>Logout</Button>
            ): (
          <></>
    )
    }
      </div>
      
      
      </Toolbar>
    </AppBar>
    <Toolbar />
    </>
    )
}

export default NavBar