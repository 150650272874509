import React, {useState, useEffect } from 'react'; 
import { Chip } from '@material-ui/core';
import { db, analytics } from '../../services/Firebase';
import './Tag.css';
import firebase from 'firebase/app';

function Tag(props) {

    const [selected, setSelected] = useState(false)

    const handleClick = () => {

        if(selected) {
            setSelected(false);

            // // legacy tagging
            // db.collection('kids').doc(props.kidID).collection('posts').doc(props.postID).collection('tags').doc(props.tagID).delete();

            // remove tag from array
            db.collection('kids').doc(props.kidID).collection('posts').doc(props.postID).update({
                tags: firebase.firestore.FieldValue.arrayRemove(props.tagID)
            })
        }
        else {
            analytics.logEvent('tag_added');

            setSelected(true);

            // // legacy tagging
            // db.collection('kids').doc(props.kidID).collection('posts').doc(props.postID).collection('tags').doc(props.tagID).set({
            //     tagname: props.label
            // });

            // set tags as array in firestore document for post
            db.collection('kids').doc(props.kidID).collection('posts').doc(props.postID).update({
                tags: firebase.firestore.FieldValue.arrayUnion(props.tagID)
            });
        }
    }

    // check to see if a tag should be selected when it loads
    useEffect(() => {
        let count = 0;


        for(const item in props.selectedList) {

            if(props.selectedList[item] === props.tagID) {
                setSelected(true);
            }
            else {
                count++;
            }
        }

        if(count === props.selectedList.length) {
            setSelected(false);
        }

    }, [props.selectedList, props.tagID] )

    // returns the chip - after checking if it should be view-only or not and selected or not
    // view-only chips that are not selected return null, only editable tags display all options
    if(props.viewOnly===true) {
        if(selected===true) {
            return(
                <>
                    <div className="tag__container">
                    <Chip 
                        label={props.label}
                        clickable
                        color={selected ? 'primary' : 'default'}
                        size='small'
                    />
                    </div>
                </>
            )
        } 
        else {
            return null;
        }
    }
    else {
        return(
        <>
            <div className="tag__container">
            <Chip 
                label={props.label}
                clickable
                // variant={selected ? 'default' : 'outlined'}
                color={selected ? 'primary' : 'default'}
                onClick={handleClick}
                size='small'
            />
            </div>
        </>
    )
    }

    
}

export default Tag