import React from 'react' 
import Login from '../services/UserAuth';
import './LoginScreen.css'
import BannerIcon from '../img/logo.svg';


export default function LoginScreen() {
    return(
        <>
        <div className="login">
            <img
                className="login__header_img"
                src={BannerIcon}
                alt="Logo"
            />
            <div className="login__subtitle">
                <h2>Home Learning Journal</h2>
            </div>
            <div className="login__signin_container">
                <h2>Login or sign up for free</h2>
                <Login></Login>
            </div>
            <div className="login__bottom_text">
                <a href="/privacy.html">Privacy Policy</a>
            </div>
        </div>
        </>
    )
}