import React, { useEffect } from 'react';
import './Post.css';
import Avatar from "@material-ui/core/Avatar";
import DefaultAvatar from './img/default_avatar.PNG';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, ButtonGroup, Input, Divider } from '@material-ui/core';
import { db, analytics } from './services/Firebase';
import ReactTimeAgo from 'react-time-ago';
import EditTagsGroup from './components/tags/EditTagsGroup.js'
import CloseIcon from '@material-ui/icons/Close';

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '75%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


function Post( { username, caption, imageURL, kidID, id, postID, timestamp, taggroup='NA', valid }) {

  const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [optionsOpen, setOptionsOpen] = React.useState(false);
    const [editCaptionOpen, setEditCaptionOpen] = React.useState(false);
    const [deletePostOpen, setDeletePostOpen] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);

    const [newCaption, setNewCaption] = React.useState('');
    
    const handleOptionsOpen = () => { 
      analytics.logEvent('post_options_accessed');
      setOptionsOpen(true); };
  
    const handleOptionsClose = () => { setOptionsOpen(false); };

    const handleEditOpen = () => {
      setOptionsOpen(false);
      setEditCaptionOpen(true);
    };

    const handleEditClose = () => { setEditCaptionOpen(false); };

    const handleDeleteOpen = () => {
      setOptionsOpen(false);
      setDeletePostOpen(true);
    };

    const handleDeleteClose = () => { setDeletePostOpen(false);
    };

    const updatePost = (event) => {
        event.preventDefault();

        db.collection('kids').doc(kidID).collection('posts').doc(postID).set({
            caption: newCaption
        }, {merge: true});
    
        setOptionsOpen(false);
        setEditCaptionOpen(false);
        setNewCaption('');
    }

    const deletePost = (event) => {
      event.preventDefault();

      db.collection('kids').doc(kidID).collection('posts').doc(postID).delete();

      setOptionsOpen(false);
      setDeletePostOpen(false);
    }

    const optionsBody = (
        <div style={modalStyle} className={classes.paper}>
          <div className='post__modalheader'>
            <h3>Edit Post</h3>
            <CloseIcon onClick={handleOptionsClose}/>
          </div>
          <Divider />

          <ButtonGroup
            orientation="vertical"
            color="default"
            aria-label="vertical outlined primary button group"
            fullWidth={true}
            size="large"
          >
            <h4>Areas Of Learning</h4>
            <div className="editTags__tagContainer">
              
              <EditTagsGroup tags={tags} selectedTags={selectedTags} kidID={kidID} postID={postID} setSelectedTags={setSelectedTags} viewOnly={false}/>
            </div>
            <Divider />
            <br />
            <h4>Other options</h4>

            <Button onClick={handleEditOpen}>Edit caption</Button>
            <Button onClick={handleDeleteOpen}>Delete</Button>
          </ButtonGroup>
        </div>
      );

      const editBody = (
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Update Post Details</h2>
            <form className="post__updatePostModal">
              <Input 
                placeholder=""
                type="text"
                value={newCaption}
                onChange={(e) => setNewCaption(e.target.value)}  
              />
              <Button type="submit" onClick={updatePost}>Update</Button>
            </form>
        </div>
      );

      const deleteBody = (
        <div style={modalStyle} className={classes.paper}>
          <h3 id="delete_confirmation">Delete post - are you sure?</h3>
          <ButtonGroup
            orientation="vertical"
            color="secondary"
            aria-label="vertical contained primary button group"
            fullWidth="true"
            size="large"
            variant="contained"
          >
            <Button onClick={deletePost}>Delete</Button>
          </ButtonGroup>
        </div>
      );

      // could this be tidied up? is this going to cause lots of unnecessary reads? will be pagination proof but
      // is doing 2 firestore reads per post 
      useEffect(() => {
        // todo: make tag group selected dynamic, not just hardcoded to early years
        db.collection('tags').where('taggroup', '==', 'Early Years').onSnapshot(snapshot => {
          setTags(snapshot.docs.map(doc => ({
            key: doc.id,
            tag: doc.data()
          })))
        })

        // db.collection('kids').doc(kidID).collection('posts').doc(postID).onSnapshot(snapshot => {
        //   // setSelectedTags(snapshot.docs.map(doc => ({
        //   //   selectedTag: doc.id
        //   // })))

        //   console.log("sbapshot", snapshot.id)

        // })

        db.collection('kids').doc(kidID).collection('posts').doc(postID).onSnapshot(snapshot => {
          // setSelectedTags(snapshot.get('tags'))
          var selTags = snapshot.get('tags')
          if(selTags != null) {
            setSelectedTags(selTags)
          }

        })

 
        
      }, [taggroup, kidID, postID])


    // check if post is valid before rendering - half-formed posts (ones having tags added before the file upload complete)
    // should be suppressed
    if(valid === 'No') {
      return null;
    }
    else {

      return (
          <>
              <Modal
                open={optionsOpen}
                onClose={handleOptionsClose}
                aria-labelledby="Post Options"
                aria-describedby="Lets you edit or delete a post"
                >
                  {optionsBody}
              </Modal>

              <Modal
                open={editCaptionOpen}
                onClose={handleEditClose}
                aria-labelledby="Post Options"
                aria-describedby="Lets you edit or delete a post"
                >
                  {editBody}
              </Modal>

              <Modal
                open={deletePostOpen}
                onClose={handleDeleteClose}
                aria-labelledby="Post Options"
                aria-describedby="Lets you edit or delete a post"
                >
                  {deleteBody}
              </Modal>


              <div className="post">
                  <div className="post__header">
                      <Avatar 
                          className="post__avatar"
                          alt='DefaultUsername'
                          src={DefaultAvatar}
                      />
                  
                      <h4>Posted <ReactTimeAgo date={timestamp.seconds * 1000} timeStyle="round-minute"/> by {username}</h4>
                      
                      <div className="post__more">
                          <MoreHorizIcon onClick={handleOptionsOpen}></MoreHorizIcon>
                      </div>
                  </div>


                  <img className="post__image" src={imageURL} alt=""/>

                  <h4 className="post__text">{caption} </h4>
                  
                  <div className="editTags__tagContainer">
                    <EditTagsGroup tags={tags} selectedTags={selectedTags} kidID={kidID} postID={postID} setSelectedTags={setSelectedTags} viewOnly={true}/>
                  </div>
              </div>
          </>
      )
  }
}

export default Post
