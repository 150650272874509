import React, { useState, useEffect } from 'react'; 
import { auth } from './services/Firebase';
import './App.css';
import LoginScreen from './nav/LoginScreen';
import NavBar from './nav/Navbar';
import Feed from './Feed';
import KidList from './KidList';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import BottomBar from './nav/BottomBar';

function App() {

  const [user, setUser] = useState(null);
  const [kid, setKid] = useState(null); // a kid being selected will load the feed for that kid

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00ff93',
        mainGradient: "linear-gradient(#00D09E, #00ff93)",
      },
      secondary: {
        main: '#002325',
      },
    },
    typography: {
      fontFamily: 'Fredoka One',
    }
  });


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser); // will survive a refresh! ^_^
      } else {
        setUser(null);
      }
    })

    return () => {
      unsubscribe(); // detach listener so you're not duplicating backend listener
    }
  }, [user]);


  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        
        {user && <NavBar setUser={setUser} setKid={setKid} user={user} kid={kid}></NavBar>}

        {!user && 
          <div className="app__login">
            <LoginScreen />
          </div>
        }

        {user && !kid && <KidList selectedKid={setKid}></KidList>}

        {user && kid && 
        <>
          <Feed kid={kid}></Feed> 
          <BottomBar username={user.displayName} kid={kid} setKid={setKid}></BottomBar>
        </>
        }

      </div>
    </ThemeProvider>
  );
}

export default App;
