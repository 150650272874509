import React from 'react'; 
import './EditTagsGroup.css';
import Tag from './Tag.js'

function EditTagsGroup(props) {

    //console.log("Got tags ", props.tags)
    // console.log("... and selected", props.selectedTags)

    return(
        props.tags.map(({key, tag}) => (
            <Tag key={key} 
                 label={tag.tagname} 
                 tagID={key} 
                 selectedList={props.selectedTags} 
                 kidID={props.kidID} 
                 postID={props.postID} 
                 setSelectedTags={props.setSelectedTags} 
                 viewOnly={props.viewOnly}/>
        ))
    )
}

export default EditTagsGroup