import React, { useState, useEffect } from 'react'; 
import { db, analytics } from './services/Firebase';
import './Feed.css';
import Post from './Post';
import firebase from 'firebase/app';
import InfiniteScroll from 'react-infinite-scroll-component';

function Feed(props) {

  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const initialPageSize = 8;
  const pageSize = 5;

  analytics.logEvent('childs_feed_accessed');

  useEffect(() => {
    db.collection('kids').doc(props.kid).collection('posts').orderBy('timestamp', 'desc').limit(initialPageSize).onSnapshot(snapshot => {
        setPosts(snapshot.docs.map(doc => ({
          id: doc.id,
          post: doc.data()
        })));
      })
    }, 
  [props.kid])


  // used by infinite scroller to page in more data
  async function fetchPage() {

    try {
      let getMoreQuery = db.collection('kids').doc(props.kid).collection('posts').orderBy('timestamp', 'desc').startAfter(posts[posts.length-1].post.timestamp).limit(pageSize)
      let docSnapshot = await getMoreQuery.get()
      let docData = docSnapshot.docs.map(doc => ({
                      id: doc.id,
                      post: doc.data() 
      }))

      // append new posts to existing posts
      setPosts([...posts, ...docData])

      if(docData.length === 0) {
        setHasMore(false)
      }
    } catch (e) {
      console.log('Delay paging')
    }
    
  }

  if(posts.length===0) {
    return(
      <>
        <h2>Time to add a photo!</h2>
        <div className="feed__emptyexplainer">
          <h4>Click the "Add Entry" button at the bottom</h4>
          <h4>Take a new photo 📸 or choose one you've already taken 🎞️</h4>
          <h4>Add a caption 📝 and tag the areas of learning 💡 Then it'll show up in your child's feed</h4>
        </div>
      </>
      )
    } else {
      return (
        <>
        <InfiniteScroll
          dataLength={posts.length}
          next={fetchPage}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>That's it - you've seen everything!</b>
            </p>
            }
        >
          
          {posts.map(({id, post}) => (
                // passing the id (key) into the map means it will only refresh new ones
                // also, the timestamp gets a default value because it takes a bit of time to get the firestore timestamp back,
                // so just needs a cheeky now() to cover the first refresh after it is posted
                <Post kidID={props.kid} 
                      postID={id} 
                      key={id} 
                      taggroup={post.taggroup}
                      username={post.username} 
                      caption={post.caption} 
                      imageURL={post.imageURL} 
                      timestamp={post.timestamp || firebase.firestore.Timestamp.fromDate(new Date())}
                      valid={post.valid || 'Yes'} />
              ))}
    
        </InfiniteScroll>
        </>
      )
    }
  }

export default Feed