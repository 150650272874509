import React from 'react'; 
import { Card, CardHeader } from '@material-ui/core';
import './KidCard.css';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from "@material-ui/core/Avatar";
import DefaultAvatar from '../img/default_avatar.PNG';
import { analytics } from '../services/Firebase'


function KidCard(props) {

    return (
        <Card>
          <CardActionArea onClick={() => {
            analytics.logEvent('child_selected');
            props.selectedKid(props.kid_id)
          }}>
            <CardHeader
                title={props.display_name}
                titleTypographyProps={{variant:'subtitle1' }}
                avatar={
                  <Avatar 
                    className="post__avatar"
                    alt='DefaultUsername'
                    src={DefaultAvatar}
                  />}
            />
          </CardActionArea>
        </Card>
    )
}

export default KidCard