import React from 'react'
import { auth, db, analytics } from './services/Firebase'
import './KidList.css'
import {useCollectionData} from 'react-firebase-hooks/firestore'
import KidCard from './components/KidCard'
import AddKidCard from './components/AddKidCard'
import NoKidsMessage from './components/NoKidsMessage'
import Grid from '@material-ui/core/Grid'

function KidList(props) {

  const kidListQuery = db.collection('users').doc(auth.currentUser.uid).collection('kids');
  const [kidList] = useCollectionData(kidListQuery, {idField: 'kid_id'});
  
  analytics.logEvent('kidlist_accessed');
  
  return(
    <>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >

        { kidList != null && kidList.length === 0 &&
            <NoKidsMessage/>
        }

        { kidList != null && kidList.length > 0 && 
          <Grid item style={{ minWidth: "78%"}}>
            <h2>Your Learning Journals</h2>
          </Grid>
        }
        
        
        <Grid item style={{ minWidth: "80%"}}>
         <AddKidCard></AddKidCard>
        </Grid>


      {kidList && kidList.map(({name, kid_id}) => {
        return (
          <Grid key={kid_id} item style={{ minWidth: "80%"}}>
            <KidCard key={kid_id} kid_id={kid_id} display_name={name} selectedKid={props.selectedKid}></KidCard>
          </Grid>
        )
      })}
      
      </Grid>
    </>
  )
}

export default KidList