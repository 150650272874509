import React, { useEffect, Suspense} from 'react' 
import './Navbar.css'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import ImageUpload from '../components/ImageUpload'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@material-ui/icons/Home'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Modal from '@material-ui/core/Modal';
import { analytics } from '../services/Firebase'
// import firebase from 'firebase/app';


const PDF = React.lazy(() => import('../components/PDF'));

// // ADMIN FUNCTION TO FIX TAG REFACTOR
// async function fixtags() {
//   console.log("Tag fixing running")
//   var kids = await db.collection('kids').get()
//   console.log("kids:", kids.docs)

//   for(var item in kids.docs) {
//     // console.log("item:", kids.docs[item].id)
//     var posts = await db.collection('kids').doc(kids.docs[item].id).collection('posts').get()
//     if(posts != null) {
//       // console.log("posts:", posts.docs)
    
//           for(var p in posts.docs) {

//               console.log("post: ", posts.docs[p].id)

//               var tags = await db.collection('kids').doc(kids.docs[item].id).collection('posts').doc(posts.docs[p].id).collection('tags').get()
//               console.log("tags: ", tags.docs)

//               if(tags != null) {
//                 for(var t in tags.docs) {
//                   console.log("TAG: ", tags.docs[t].id)
         
//                   // set tags as array in firestore document for post
//                   db.collection('kids').doc(kids.docs[item].id).collection('posts').doc(posts.docs[p].id).update({
//                     tags: firebase.firestore.FieldValue.arrayUnion(tags.docs[t].id)
//                    });

//                 }
//               }
            


//           }


//   }


//   }  
// }

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
    backgroundColor: '#002325',
    color: 'white',
    width: "75%",
  },
  modalpaper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  PDFButton: {
    padding: '10px',
    margin: '0 auto',
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function BottomBar(props) {

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  const [PDFModalOpen, setPDFModalOpen] = React.useState(false);

  const handlePDFModalOpen = () => { 
    analytics.logEvent('pdf_modal_opened');
    setPDFModalOpen(true); };  
  const handlePDFModalClose = () => { setPDFModalOpen(false); };

  const PDFModalBody = (
    <div style={modalStyle} className={classes.modalpaper}>
      <h3>Download Journal to PDF</h3>
      <Suspense fallback={<div>Loading...</div>}>
        <PDF kid={props.kid}></PDF>
      </Suspense>
    </div>
  );



  useEffect(() => {
    // let isMounted = true; // note this flag denote mount status

    // return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleHome = () => {
    props.setKid(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem alignItems="center">
          <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.PDFButton}
          startIcon={<PictureAsPdfIcon />}
          onClick={handlePDFModalOpen}
          >
            Export journal to PDF
          </Button>

        </ListItem>

        <ListItem>
          This will create a PDF report of your journal which you can email or share with others.
        </ListItem>
{/* 
        <ListItem alignItems="center">
          <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.PDFButton}
          onClick={fixtags}
          >
            Fix Tags
          </Button>

        </ListItem> */}

      </List>
      <Divider />
    </div>
  );


    return(
      <>
        <AppBar position="fixed" color="secondary" className={classes.appBar}>
          <Toolbar>
            
            <IconButton aria-label="home" onClick={handleHome} color="primary">
              <HomeIcon/>
            </IconButton>

            <ImageUpload username={props.username} kid={props.kid}></ImageUpload>

            <div className={classes.grow} />

            <IconButton edge="end" aria-label="more" onClick={toggleDrawer("right", true)} color="primary">
              <MoreHorizIcon/>
            </IconButton>

            <Drawer anchor={"right"} 
                    open={state["right"]}  
                    onClose={toggleDrawer("right", false)} 
                    classes={{ paper: classes.paper }}>
              {list("right")}
            </Drawer>

          </Toolbar>
        </AppBar>
        <Toolbar />

        <Modal
              open={PDFModalOpen}
              onClose={handlePDFModalClose}
              aria-labelledby="Post Options"
              aria-describedby="Lets you edit or delete a post"
              >
                {PDFModalBody}
            </Modal>
    </>
    )
}
