//import firebase from "firebase";
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/analytics';    // for realtime database
import 'firebase/firestore';    // for realtime database

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCF3lgjey5UpeNNfFfqH5OHCOuefCXc-mw",
    authDomain: "homeschool-59249.firebaseapp.com",
    databaseURL: "https://homeschool-59249.firebaseio.com",
    projectId: "homeschool-59249",
    storageBucket: "homeschool-59249.appspot.com",
    messagingSenderId: "1057810087742",
    appId: "1:1057810087742:web:8265a5567c8c833f13b66f",
    measurementId: "G-9JYPB6DMD1"
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();

export {db, auth, storage, analytics};