import { Button, Fab, TextField, Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {storage, db, analytics } from '../services/Firebase'
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import AddIcon from '@material-ui/icons/Add';
import './ImageUpload.css'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import EditTagsGroup from './tags/EditTagsGroup.js';
import Resizer from 'react-image-file-resizer';

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}
  
const useStyles = makeStyles((theme) => ({
paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
},
imgUploadButton: {
    position: 'absolute',
    zIndex: 1,
    top: -15,
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: 150
},
}));

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 
                             2000, // max width
                             2000, // max height
                             'PNG', 
                             100,  // quality
                             0,    // rotation
    uri => {
      resolve(uri);
    },
    'blob'
    );
});

function ImageUpload({username, kid}) {
    const [image, setImage] = useState(null);
    const [progress, setProgress] = useState(0);
    const [caption, setCaption] = useState('');

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    // states to make upload button and progress bar hidden or visible
    const [uploadHidden, setUploadHidden] = useState(false);
    const [progressHidden, setProgressHidden] = useState(true);

    // for tagging
    const [tags, setTags] = React.useState([]);

    // so tags can be added to document prior to upload
    const [newDocRef, setNewDocRef] = useState('');
    // store selected tags above the tag group
    const [selectedTags, setSelectedTags] = React.useState([]);

    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', 'image/*')
    const [newDocID, setNewDocID] = useState(uuidv4());

    useEffect(() => {
        // make firestore ID before upload happens
        setNewDocRef(db.collection('kids').doc(kid).collection('posts').doc().id)

        // todo: make tag group selected dynamic, not just hardcoded to early years
        db.collection('tags').where('taggroup', '==', 'Early Years').onSnapshot(snapshot => {
          setTags(snapshot.docs.map(doc => ({
            key: doc.id,
            tag: doc.data()
          })))
        })
        
      }, [kid] )


    const handleFileSelect = (e) => {
        e.preventDefault();
        fileSelector.click();
        setNewDocID(uuidv4());
      }
    
    // for modal
    const handleOpen = () => {
    
        // create placeholder document - required so tags can be updated to it
        db.collection('kids').doc(kid).collection('posts').doc(newDocRef).set({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            valid: 'No'
        });

        setOpen(true);
    };
    
    // for modal
    const handleClose = () => {
        setOpen(false);
    };

    // for file upload
    const handleChange = async (e) => {
        if (e.target.files[0]) { // get the first file, if multiple selected
            // resize image 
            try {
                const file = e.target.files[0];
                const resizedImage = await resizeFile(file);
                setImage(resizedImage);
            } catch(err) {
                console.log(err);
            }
        }
        handleOpen()
    }

    const handleUpload = () => {
        analytics.logEvent('picture_uploaded');

        // button/progress bar visibility switch
        setUploadHidden(true);
        setProgressHidden(false);

        const img_stored_name = newDocID;
        const uploadTask = storage.ref(`images/${img_stored_name}`).put(image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // progress function
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            (error) => {
                // error function
                console.log(error);
            },
            () => {
                // complete function
                storage
                    .ref("images")
                    .child(img_stored_name)
                    .getDownloadURL()
                    .then(url => {
                        db.collection('kids').doc(kid).collection('posts').doc(newDocRef).set({
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            caption: caption,
                            imageURL: url,
                            username: username

                        });

                        setProgress(0);
                        setCaption('');
                        setImage(null);
                        setOpen(false);

                        // button/progress bar visibility switch
                        setUploadHidden(false);
                        setProgressHidden(true);
                    })
            }
        )

        // set valid flag
        db.collection('kids').doc(kid).collection('posts').doc(newDocRef).set({
            valid: 'Yes'
        });

        // set next doc ref for another upload
        setNewDocRef(db.collection('kids').doc(kid).collection('posts').doc().id);

    }


    //fileSelector.setAttribute('onchange', 'handleChange');
    fileSelector.addEventListener('change', handleChange)

    // modal for image uploader
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div className="upload__modal__header">
                <h2 id="simple-modal-title">📸 Photo selected</h2>                    
            </div>
            <Divider />
            <form className="upload__addImageForm">
                
                <h4>Areas Of Learning</h4>

                <div className="upload__modal__tags">
                <EditTagsGroup tags={tags} kidID={kid} postID={newDocRef} selectedTags={selectedTags} setSelectedTags={setSelectedTags} viewOnly={false}/>
                </div>
                
                <TextField id="outlined-name" variant="outlined" label="Enter caption" className="upload__addCaption" type="text" onChange={event => setCaption(event.target.value)} value={caption}/>
                
                <div className="upload__modal__submitButton" hidden={uploadHidden}>
                    <Button onClick={handleUpload} variant="contained" fullWidth={true} color="primary">
                        Add Picture
                    </Button>
                </div>
                <div className="upload__modal_progressBar" hidden={progressHidden}>
                    <progress className="imageupload__progress" value={progress} max="100"/>
                </div>
            </form>

        </div>
      );

    return (
        <>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <div>
            <Fab variant="extended" size="large" color="primary" aria-label="add" onClick={handleFileSelect} className={classes.imgUploadButton}>
                <AddIcon />
                Add entry
            </Fab>

            </div>

            
            {/* <progress className="imageupload__progress" value={progress} max="100"/>
            <input type="text" placeholder="Enter caption" onChange={event => setCaption(event.target.value)} value={caption}/>
            <input type="file" onChange={handleChange}/>
            <Button onClick={handleUpload}>
                Upload
            </Button> */}
        </>
    )
}

export default ImageUpload